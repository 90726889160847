// extracted by mini-css-extract-plugin
export var bottomText = "herosection-module--bottom-text--qCtHs";
export var buttonWrapper = "herosection-module--button-wrapper---eqPY";
export var callToActionBtn = "herosection-module--call-to-action-btn--1pBrq";
export var content = "herosection-module--content--gaNaj";
export var contentWrapper = "herosection-module--content-wrapper--Etn7E";
export var imgWrapper = "herosection-module--img-wrapper--gxme6";
export var mark = "herosection-module--mark--LNWGv";
export var secondaryActionBtn = "herosection-module--secondary-action-btn--NCU4A";
export var subtitle = "herosection-module--subtitle--sRNEl";
export var textWrapper = "herosection-module--text-wrapper--XZ2Ao";
export var title = "herosection-module--title--OsVHa";
export var wrapper = "herosection-module--wrapper--gsgSy";