// extracted by mini-css-extract-plugin
export var bgOne = "modules-module--bgOne--JmSN-";
export var bgThree = "modules-module--bgThree--97EOs";
export var bgTwo = "modules-module--bgTwo---Ef5p";
export var card = "modules-module--card--NboSC";
export var cardDesc = "modules-module--cardDesc--7asFH";
export var cardHeader = "modules-module--cardHeader--mcLS1";
export var cardOne = "modules-module--cardOne--A7QLw";
export var cardTitle = "modules-module--cardTitle--pkNzg";
export var cardTwo = "modules-module--cardTwo--wy4H4";
export var cardType = "modules-module--cardType--icx4F";
export var cards = "modules-module--cards--QuPfZ";
export var mark = "modules-module--mark--ZpWgn";
export var section = "modules-module--section--O5Q3O";
export var sectionDesc = "modules-module--sectionDesc--9yko2";
export var sectionTitle = "modules-module--sectionTitle---v0Yx";
export var wrapper = "modules-module--wrapper--3u-ub";