// extracted by mini-css-extract-plugin
export var carousel = "intelligence-module--carousel--tQlSw";
export var carouselWrapper = "intelligence-module--carouselWrapper--KEkKa";
export var coverImg = "intelligence-module--coverImg--gbn32";
export var description = "intelligence-module--description--dJX4S";
export var feature = "intelligence-module--feature--LNHur";
export var features = "intelligence-module--features--9Ydy1";
export var header = "intelligence-module--header--VgEi0";
export var panel = "intelligence-module--panel--UyA6a";
export var panelImg = "intelligence-module--panelImg--HksbD";
export var panelText = "intelligence-module--panelText--ETcGr";
export var panelTitle = "intelligence-module--panelTitle--z3pBZ";
export var section = "intelligence-module--section--uPZCJ";
export var tablist = "intelligence-module--tablist--jpFep";